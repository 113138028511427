<template>
	<div>
		<div class="no-print">
			<div class="row">
				<div class="mr-auto ml-3 mb-3">
					<e-select
						v-model="residence_selected"
						:options="residences"
						:searchable="true"
						label="lieu_label"
						:placeholder="$t('monte.choix_lieu')"
						:loading="loadingLieu"
					>	
						<template slot="singleLabel" slot-scope="{ option }">{{ option.lieu_label }}</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>
				</div>
				<div v-if="planning.planning_id" class="ml-auto mr-3 mb-3">
					<button class="btn btn-secondary mr-3" @click="openModalSaillie">{{ $t('monte.saillie_lot') }}</button>
					<button class="btn btn-secondary mr-3" onclick="window.print()">{{ $t('monte.imprimer_planning') }}</button>
					<button class="btn btn-secondary" @click="openModalPrint">{{ $t('monte.imprimer_planning_period') }}</button>
					<button v-if="planning.planning_horse" class="btn btn-secondary ml-2" @click="openModalSelectHorse">{{ $t('horse.print_infos_horse') }}</button>
				</div>
			</div>
			<div class="row">
				<div class="col-12 d-flex justify-content-between align-items-center">
					<button class="btn btn-secondary" @click="prev"><font-awesome-icon :icon="['fal', 'long-arrow-left']" /></button>
					<div class="d-flex">
						<h3 class="mr-2" style="line-height: 35px;">{{ date_label }}</h3>
						<e-datepicker class="d-inline" v-model="date" id="date" @input="change_date"></e-datepicker>
						<button class="btn btn-sm mx-2" v-bind:class="isToday" @click="change_date_today" >{{$t('global.aujourdhui')}}</button>
					</div>
					<button class="btn btn-secondary" @click="next"><font-awesome-icon :icon="['fal', 'long-arrow-right']" /></button>
				</div>
			</div>

			<template v-if="season.length != 0 && residence_selected.lieu_id && planning.planning_id && !planningLoaded">
				<div class="table-responsive table-calendrier mx-0">
					<div class="row calendar_line no-gutters mt-4 d-none d-md-flex">
						<div class="col-auto calendar_horse_name"><div></div></div>
						<div class="col text-center calendar_horraire" v-for="(schedule, index) in planning.planning_schedule" :key="index">
							{{ schedule.schedule_start }} - {{ schedule.schedule_end }}
						</div>
					</div>

					<div class="row calendar_line no-gutters" v-for="(horse, index) in planning.planning_horse" :key="index">
						<div class="col-12 col-md-auto calendar_horse_name"><div>{{ horse.planninghorse_stallion.horse_nom }}</div></div>
						<div class="col-12 col-md calendar_schedule" v-for="(schedule, index_2) in planning.planning_schedule" :key="index_2">
							<template v-if="schedule.schedule_info[horse.planninghorse_stallion.horse_id]">
								<div v-if="Object.keys(schedule.schedule_info[horse.planninghorse_stallion.horse_id]).length > 1" class="alert alert-danger text-center" role="alert">
									Attention conflit !
								</div>
								<template v-for="(slot, index) in schedule.schedule_info[horse.planninghorse_stallion.horse_id]">
									<CellCalendarMain
										v-bind:key="index"
										:schedule="schedule"
										:params="slot"
										:edit_schedule.sync="edit_schedule"
										:delete_schedule.sync="delete_schedule"
										:season_id="season[0].season_id"
										:previous_season="previous_season.season_id"
									/>
								</template>
							</template>
							<button v-else class="btn btn-secondary" @click="add(horse.planninghorse_stallion.horse_id, schedule.schedule_id)"><font-awesome-icon :icon="['far', 'plus']" /></button>
						</div>
					</div>
				</div>





			</template>
			<template v-else-if="!planning.planning_id && planningLoaded">
				<LoadingSpinner class="col-12" />
			</template>
			<template v-else>
				<div class="alert alert-warning mt-5">{{ $t('monte.no_planning') }}</div>
			</template>

			<ModalConfirm 
				ref="modal_delete_schedule" 
				:icon="['fal', 'trash-alt']"
				:text_title="$t('monte.modal_title')"
				:text_question="$t('monte.modal_question')"
				:text_message="$t('modal.general.avertissement_suppression')"
				:text_button_ok="$t('action.supprimer')"
				:text_button_cancel="$t('global.annuler')"
				:callback_validation="deleteSchedule"
				:callback_cancel="closeModal" />


		<ModalPrint 
			ref="modal_print"
			:date="date" 
			:lieu_id="residence_selected.lieu_id"
			componentName="PrintCalendarMain" />

		<ModalAddSaillie
			v-if="season.length != 0 && planning.planning_id"
			ref="modal_saillie"
			:date="date" 
			:planning="planning"
			:season_id="season[0].season_id"
		/>

		</div>
		<div class="print" id="planningMontemainPrint">
			<h1>{{ dateFormat }}</h1>
			<div v-for="(schedule, index_2) in planning.planning_schedule" :key="index_2">
				<div v-if="Object.keys(schedule.schedule_info).length !== 0">
					<h2 class="my-1">{{schedule.schedule_start}} - {{schedule.schedule_end}}</h2>
					<div class="slot py-2" v-for="(stallions, index_3) in schedule.schedule_info" :key="index_3">
						<div v-if="Object.keys(stallions).length > 1 " class="alert alert-danger text-center" role="alert">
									Attention conflit !
						</div>
						<div class="row" v-for="(slot, index_4) in stallions" :key="index_4">
							<div class="col-3 stallion">
								<span class="ml-3">{{slot.schedulehorse_stallion.horse_nom}}</span>
							</div>
							<div class="col-4 mare"><b>{{slot.schedulehorse_mare.horse_nom}}</b>, {{slot.schedulehorse_mare.horse_robe}}</div>
							<div class="col-5" v-if="slot.schedulehorse_mare.horse_transpondeur">{{slot.schedulehorse_mare.horse_transpondeur}}</div>
							<div v-if="slot.schedulehorse_contact" class="offset-3 col-4">{{slot.schedulehorse_contact.contact_firstname}} {{slot.schedulehorse_contact.contact_lastname}}</div>
							<div v-if="slot.schedulehorse_contact" class="col-5">
								<template v-for="phone in slot.schedulehorse_contact.phones">
									{{formatPhone(phone.phone_combine)}}, 
								</template>
							</div>
							<div class="comment col-12" v-if="slot.schedulehorse_commentaire != ''">
							<span class="ml-3">{{slot.schedulehorse_commentaire}}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<p class="text-right"><small>Imprimé le {{ getNow }}</small></p>
		</div>

		<ModalSelectHorse ref="modal_select_horse" />
	</div>
</template>

<script type="text/javascript">
	import Vue from 'vue'
	import PlanningMonte from "@/mixins/PlanningMonte.js"
    import Lieux from "@/mixins/Lieux.js"
	import ShutterPanel from 'GroomyRoot/components/Utils/ShutterPanel'
	import Shutter from '@/mixins/Shutter'
	import MonteShutters from '@/mixins/shutters-manager/Monte'
	import Gynecologie from '@/mixins/Gynecologie'
	import { parsePhoneNumberFromString as parseMax, AsYouType } from 'libphonenumber-js/max'

	export default {
		name: 'CalendarMonteMain',
		mixins: [PlanningMonte, Shutter, MonteShutters, Lieux, Gynecologie],
		data () {
			return {
				date: new Date(),
				date_label: '',
				planning: {},
				season: {},
				previous_season: {},
				edit_schedule: null,
				delete_schedule: null,
				schedulehorse_id: null, // créneau que l'on souhaite supprimer dans la modal

				residences: [],
				residence_selected: {},

				loadingLieu: false,
				planningLoaded: false
			}
		},

		mounted() {
			this.init_component()
		},
		computed :{
			isToday:function() {
				let today = new Date
				
				if(this.date.getDate()==today.getDate() && this.date.getMonth()==today.getMonth() && this.date.getFullYear()==today.getFullYear()){
					return "btn-primary"
				}
				return "btn-outline-primary"
			},
			dateFormat:function() {
				return new Intl.DateTimeFormat('fr', { dateStyle: 'full'}).format(this.date);
			},
			getNow: function() {
                    let today = new Date();
                    let date = new Intl.DateTimeFormat('fr').format(today);
                    let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                    let dateTime = date +' '+ time;
                    return dateTime;
            }
		},
		methods: {
			async init_component() {
				this.date = new Date();
				this.formatDate()
				this.loadResidence()
			},
			formatPhone(number) {
				return new AsYouType(Vue.i18n.locale()).input(number)
			},
			formatDate() {
				this.date_label = this.getTrad("monte.jours."+this.date.getDay())
			},

			async loadResidence() {
				this.loadingLieu = true
				this.season = await this.getCurrentSeason(this.date)

				if(this.season.length === 0) {
					this.loadingLieu = false
					return
				}

				this.previous_season = await this.getPreviousSeasonFromSeason(this.season[0].season_id)

				this.residences = await this.getResidences()
				const residence = await this.getResidencePlanningDefault(this.season[0].season_id, "main")

				if(residence) {
					this.residence_selected = this.residences.find(lieu => lieu.lieu_id == residence.planning_lieu)
				}
				this.loadingLieu = false
			},

			async loadPlanning() {
				this.planningLoaded = true
				this.planning = {}
				if(this.season.length != 0){
					const planning = await this.getPlanning(this.season[0].season_id, "main", this.date, this.residence_selected.lieu_id)
					if(planning.date == this.date) {
						this.planning = planning
						this.planningLoaded = false
					}
				}
			},

			async prev() {
				let date = new Date(this.date.getTime())
				date.setDate(date.getDate() - 1)
				this.date = date
				this.formatDate()
				this.loadPlanning()
			},

			async next() {
				let date = new Date(this.date.getTime())
				date.setDate(date.getDate() + 1)
				this.date = date
				this.formatDate()
				this.loadPlanning()
			},

			add(horse_id, schedule_id) {
                this.shutterPanel().keepGiven('monte-main-calendar')

				const shutter = this.monteShutters['monte-main-ajout']
				shutter.props.schedule_id = schedule_id
				shutter.props.horse_id = horse_id
				shutter.props.planning_id = this.planning.planning_id
				shutter.props.date_preselected = this.date
				shutter.props.season_id = this.season[0].season_id

				shutter.props.schedule_info = null

				shutter.onOk = () => {
					this.loadPlanning()
				}

				this.shutterPanel().open(shutter)
			},

			async deleteSchedule() {
				await this.deleteScheduleHorse(this.schedulehorse_id)
				this.closeModal()
				this.loadPlanning()
			},

			closeModal() {
				this.schedulehorse_id = null
				this.$refs.modal_delete_schedule.closeModal()
			},

			openModalPrint() {
				this.$refs.modal_print.openModal()
			},

			openModalSaillie() {
				this.$refs.modal_saillie.openModal()
			},

			change_date() {
				this.formatDate()
				this.loadResidence()
			},
			change_date_today() {
				this.date = new Date()
				this.change_date()
			},
			openModalSelectHorse() {
				this.$refs.modal_select_horse.openModal(this.planning)
			},
		},

		watch: {
			edit_schedule (obj) {
				if(!obj) return false

                this.shutterPanel().keepGiven('monte-main-calendar')

				const shutter = this.monteShutters['monte-main-ajout']
				shutter.props.schedule_info = obj.schedule_info
				shutter.props.schedule_id = obj.schedule_id
				shutter.props.horse_id = obj.horse_id
				shutter.props.season_id = this.season[0].season_id
				shutter.props.planning_id = this.planning.planning_id

				shutter.onOk = () => {
					this.loadPlanning()
				}

				this.shutterPanel().open(shutter)
			},

			delete_schedule (schedulehorse_id) {
				this.schedulehorse_id = schedulehorse_id.schedulehorse_id
				this.$refs.modal_delete_schedule.openModal()
			},

			residence_selected() {
				this.loadPlanning()
			}
		},

		components: {
			CellCalendarMain : () => import('@/components/Monte/CellCalendarMain'),
			ModalConfirm: () => import('GroomyRoot/components/Modals/ModalConfirm'),
			ModalPrint : () => import('@/components/Monte/ModalPrint'),
			ModalAddSaillie: () => import('@/components/Monte/ModalAddSaillie'),
			ModalSelectHorse : () => import('@/components/Monte/ModalSelectHorse'),
			PrintCalendarMain : () => import('@/components/Monte/PrintCalendarMain'),
			LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
		}
	}

</script>