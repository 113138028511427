export default {
	data: function() {
		return {
			monteShutters: {
				'monte-main-calendar': {
					name: 'monte-main-calendar',
					title: this.$t('monte.planning'),
					cmpPath: 'components/Monte/CalendarMonteMain',
					props: {
					}
				},
				'monte-main-calendar-beta': {
					name: 'monte-main-calendar-beta',
					title: this.$t('monte.planning'),
					cmpPath: 'components/Monte/CalendarMonteMainBeta',
					props: {
					}
				},
				'monte-main-ajout': {
					name: 'monte-main-ajout',
					title: this.$t('monte.add_jument'),
					cmpPath: 'components/Monte/AjoutMonteMain',
					props: {
						schedule_info: null,
						schedule_id: null,
						horse_id: null,
						planning_id: null,
						date_preselected: null,
						season_id: null
					}
				},
				'monte-main-ajout-beta': {
					name: 'monte-main-ajout-beta',
					title: this.$t('monte.add_jument'),
					cmpPath: 'components/Monte/AjoutMonteMainBeta',
					props: {
						schedule_info: null,
						schedule_id: null,
						horse_id: null,
						planning_id: null,
						date_preselected: null,
						season_id: null
					}
				},
				'monte-horse-ajout': {
					name: 'monte-horse-ajout',
					title: this.$t('monte.create_jument'),
					cmpPath: 'components/Monte/HorseCreationMonte',
					props: {
						season_id: null,
						contract_num: null
					}
				},
				'monte-contact-ajout': {
					name: 'monte-contact-ajout',
					title: this.$t('monte.create_contact'),
					cmpPath: 'components/Monte/ContactFormMonte',
					props: {
						horse_id: null
					}
				},
				'ajout-acte': {
					name: 'ajout-acte',
					title: this.$t('monte.create_acte'),
					cmpPath: 'components/WriteActe',
					props: {
						horse_ids: null,
						actes_ids: null,
						group_preselect: null,
						type_preselect: null,
						date_preselect: null,
						preselect: null
					}
				},
				'acte-jument': {
					name: 'acte-jument',
					title: this.$t('monte.acte_jument'),
					cmpPath: 'components/Monte/ActeJument',
					props: {
						horse_id: null,
						horse_nom: null
					}
				},
				// 'contrat': {
				// 	name: 'contrat',
				// 	title: this.$t('monte.contrat'),
				// 	cmpPath: 'components/Contract/ContractForm',
				// 	props: {
				// 		contract_id: null,
				// 		stallion_id: null
				// 	}
				// },
				'contrat': {
					name: 'contrat',
					title: this.$t('monte.contrat'),
					cmpPath: 'components/Contract/ContractFormv2',
					props: {
						contract_id: null,
						stallion_id: null
					}
				},
				'monte-ia-calendar': {
					name: 'monte-ia-calendar',
					title: this.$t('monte.planning'),
					cmpPath: 'components/Monte/CalendarMonteIA',
					props: {
					}
				},
				'monte-ia-calendar-beta': {
					name: 'monte-ia-calendar-beta',
					title: this.$t('monte.planning'),
					cmpPath: 'components/Monte/CalendarMonteIABeta',
					props: {
					}
				},
				'monte-ia-ajout': {
					name: 'monte-ia-ajout',
					title: this.$t('monte.add_jument'),
					cmpPath: 'components/Monte/AjoutMonteIA',
					props: {
						schedule_info: null,
						schedule_id: null,
						horse_id: null,
						planning_id: null,
						date_preselected: null,
						season_id: null
					}
				},
				'monte-ia-ajout-beta': {
					name: 'monte-ia-ajout-beta',
					title: this.$t('monte.add_jument'),
					cmpPath: 'components/Monte/AjoutMonteIABeta',
					props: {
						schedule_info: null,
						schedule_id: null,
						horse_id: null,
						planning_id: null,
						date_preselected: null,
						season_id: null
					}
				},
				'ajout-tiers-mare': {
					name: 'ajout-tiers-mare',
					title: this.$t('monte.tiers_mare'),
					cmpPath: 'components/Monte/MareTiers',
					props: {
						horse: null
					}
				},
                'ajout-tiers': {
                    name: 'ajout-tiers',
                    title: this.$t('tiers.ajouter_tiers'),
                    cmpPath: 'components/ShutterScreens/Tier/FormAdd',
                    onOk: this.setTier,
                    props: {
                    	
                    }
                },
                'mvt-lieu-stationnement': {
                    name: 'mvt-lieu-stationnement',
                    title: this.$t('mouvement.stationnement_edit'),
                    cmpPath: 'components/ShutterScreens/Horse/EditLieuStationnement',
                    onOk: this.loadLieuStationnement,
                    props: {
                    	horse_id: null
                    }
                },
			}
		}
	},
}
